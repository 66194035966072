import React from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ThemedSuspense } from '@futurionic/ui';

import Layout from './components/main/containers/Layout';

import SignIn from './modules/user/pages/SignIn';
import SignUp from './modules/user/pages/SignUp';
import ForgotPassword from './modules/user/pages/ForgotPassword';
import VendorSignUp from './modules/vendor/pages/VendorSignUp';

import routes from './components/main/routes';

import { logOut } from './store/authenticationSlice';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    navigate('/', { replace: true });
    dispatch(logOut);
  };
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<SignIn />} />
      <Route path="/register" element={<SignUp />} />
      <Route path="/vendor-register" element={<VendorSignUp />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route
        path="/app/*"
        element={
          <Layout
            handleLogoutClick={handleLogoutClick}
            routes={routes}
            ThemedSuspense={ThemedSuspense}
          />
        }
      />
    </Routes>
  );
}

export default App;
