/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */

import { v4 as uuidv4 } from "uuid";

const routes = [
  {
    id: uuidv4(),
    path: "/app/dashboard", // the url
    icon: "dashboardIcon", // the component being exported from icons/index.js
    name: "Dashboard", // name that appear in Sidebar
  },
  { id: uuidv4(), path: "/app/vendor", icon: "vendorsIcon", name: "Vendor" },

  {
    id: uuidv4(),
    path: "/app/locations",
    icon: "locationsIcon",
    name: "Locations",
  },
  {
    id: uuidv4(),
    path: "/app/list-employees",
    icon: "employeesIcon",
    name: "Employees",
  },
  {
    id: uuidv4(),
    path: "/app/attendance",
    icon: "AttendanceIcon",
    name: "Attendance",
  },
  {
    id: uuidv4(),
    icon: "ShiftIcon",
    name: "Shifts",
    routes: [
      {
        path: "/app/attendance/shifts",
        icon: "attendanceIcon",
        name: "New Shift",
      },
      {
        id: uuidv4(),
        path: "/app/shift-roster",
        icon: "attendanceIcon",
        name: "Roster",
      },
      {
        id: uuidv4(),
        path: "/app/roster-view",
        icon: "attendanceIcon",
        name: "Roster View",
      },

      {
        id: uuidv4(),
        path: "/app/attendance/day-off",
        name: "Day Off Management",
      },
    ],
  },

  // {
  //   id: uuidv4(),
  //   icon: "timeScheduleIcon",
  //   name: "Time & Attendence Management",
  //   routes: [
  //     {
  //       id: uuidv4(),
  //       path: "/app/capture-policy",
  //       icon: "attendanceIcon",
  //       name: "Capture Policy",
  //     },
  //     {
  //       id: uuidv4(),
  //       path: "/app/penialization-policy",
  //       icon: "attendanceIcon",
  //       name: "Penialization Policy",
  //     },
  //     {
  //       id: uuidv4(),
  //       path: "/app/regularization-policy",
  //       icon: "attendanceIcon",
  //       name: "Regularization Policy",
  //     },
  //   ],
  // },
  // {
  //   id: uuidv4(),
  //   icon: "LeavesIcon",
  //   name: "Leaves & Holidays",
  //   routes: [{ id: uuidv4(), name: "Leave Master", path: "/app/leave-master" }],
  // },

  {
    id: uuidv4(),
    icon: "payrollIcon",
    name: "Payroll",
    routes: [
      { id: uuidv4(), name: "Set Structure", path: "/app/payroll-setting" },
      { id: uuidv4(), name: "Assign Structure", path: "/app/payroll-assign" },
      {
        id: uuidv4(),
        name: "Incentive & OT",
        path: "/app/incentive-ot",
      },
      {
        id: uuidv4(),
        name: "Deduction",
        path: "/app/outstanding-employee",
      },
      { id: uuidv4(), name: "Process Payroll", path: "/app/payroll-process" },
      {
        id: uuidv4(),
        name: "Challan Generation",
        path: "/app/payroll-challan",
      },
      { id: uuidv4(), name: "Taxation", path: "/app/taxation" },
    ],
  },
  // { id: uuidv4(),
  //   path: '/app/announcement',
  //   icon: 'announcementIcon',
  //   name: 'Announcement',
  // },

  // {
  //   id: uuidv4(),
  //   icon: "openingsIcon",
  //   name: "Jobs",
  //   routes: [
  //     { id: uuidv4(), name: "Openings", path: "/app/job-openings" },
  //     { id: uuidv4(), name: "Joining Request", path: "/app/job-request" },
  //   ],
  // },

  {
    id: uuidv4(),
    path: "/app/designation-master",
    icon: "DesignationIcon",
    name: "Designations",
  },
  {
    id: uuidv4(),
    path: "/app/department-master",
    icon: "DepartmentIcon",
    name: "Departments",
  },

  { id: uuidv4(), path: "/app/holiday", icon: "holidayIcon", name: "Holiday" },

  // {
  //   id: uuidv4(),
  //   icon: "ChartsIcon",
  //   name: "Separation",
  //   routes: [
  //     // submenu
  //     { id: uuidv4(), path: "/app/resignation-requests", name: "Resignation" },
  //     { id: uuidv4(), path: "/app/termination", name: "Termination" },
  //     { id: uuidv4(), path: "/app/reports", name: "Reports" },
  //   ],
  // },
  // { id: uuidv4(),
  //   path: '/app/policy',
  //   icon: 'policyIcon',
  //   name: 'Policy',
  // },
  // {
  //   id: uuidv4(),
  //   icon: "PagesIcon",
  //   name: "Pages",
  //   routes: [
  //     // submenu
  //     { id: uuidv4(), path: "/register", name: "Create account" },
  //     { id: uuidv4(), path: "/forgot-password", name: "Forgot password" },
  //   ],
  // },

  // {
  //   id: uuidv4(),
  //   path: "/app/rolePermission",
  //   icon: "TablesIcon",
  //   name: "Role & Permissions",
  // },
  {
    id: uuidv4(),
    icon: "LeavesIcon",
    name: "Leaves",
    routes: [
      // submenu
      { id: uuidv4(), path: "/app/leave-requests", name: "Overview" },
      {
        id: uuidv4(),
        path: "/app/compensatory-request",
        name: "Compensatory Off",
      },
      { id: uuidv4(), path: "/app/leave-balance", name: "Balance" },
      { id: uuidv4(), path: "/app/leave-settings", name: "Settings" },
    ],
  },
];

export default routes;
